<template>
  <RegularStatisticsComponent :rows="rows"></RegularStatisticsComponent>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import sharedMethods from '../Shared/sharedMethods'
import ContractStatisticsComponent from '../Shared/contractStatistics.vue'
import RegularStatisticsComponent from '../Shared/regularStatistics.vue'

export default {
  name: 'StatsRenters',
  computed: {
    ...mapState({
      statistics: s => s.stats.renters.stats,
      group: s => s.stats.renters.group
    }),
    rows() {
      return sharedMethods.getRegularRows(this.statistics)
    },
  },
  async created() {
    await this.fetch()
  },
  beforeDestroy() {
    this.reset()
  },
  components: {
    RegularStatisticsComponent,
    ContractStatisticsComponent
  },
  methods: {
    ...mapActions({
      fetch: 'stats/renters/fetch'
    }),
    ...mapMutations({
      reset: 'stats/renters/reset'
    }),
  }
}
</script>
